import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import { getAuth,  } from "firebase/auth";

import { doc, setDoc } from "firebase/firestore"; 
import { Link } from 'react-router-dom'
import { Button } from '@mui/material';

function Survey1(){
    const [firstName, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [phnNbr , setPhnNbr] = useState("");
    const [homePhnNbr , setHomePhnNbr] = useState("");
    const [email, setEmail] = useState("");
    const [middleInitial, setMiddleInitial] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [street, setStreet] = useState("");
    const [livingState, setLivingState] = useState("");
    const [city, setCity] = useState("");
    const [primaryInsurance, setPrimaryInsurance] = useState("");
    const [supplementalInsurance, setSupplementalInsurance] = useState("");
    const [primaryInsuranceNumber, setPrimaryInsuranceNumber] = useState("");
    const [supplementalInsuranceNumber, setSupplementalInsuranceNumber] = useState("");
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const dme = urlParams.get('dme')
    console.log("dme:", dme);
    const { currentUser } = useAuth()
    const states = ['-','Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']
    const genders = ['-', 'Male', 'Female']

    let home2 = false
    
    
    const navClicker = () => {
        home2 = true
    }
    console.log(currentUser.uid)

    const  saveAnswer = async (event) => {
        event.preventDefault();

        // addressRef = doc(db, "users" , currentUser.uid)
    
        console.log("SUBMITTED")

        const auth = getAuth();
        
        
        const userRef = doc(db, "users" , currentUser.uid,)

        console.log(currentUser.uid)

        await setDoc(userRef, {
            firstName,
            lastname,
            assignedDME: dme,
            email,
            middleInitial,
            dateOfBirth, 
            gender,
            phnNbr,
            zipCode,
            street,
            livingState,
            city,
            "position": 1,
            homePhnNbr,
            primaryInsurance,
            supplementalInsurance,
            primaryInsuranceNumber,
            supplementalInsuranceNumber,

            'phoneNumber': phnNbr,
            "_pdfplum_config": {
                "outputStorageBucket": "gs://a-custom-fit-in-motion.appspot.com",
                "outputFileName": `${auth.currentUser.uid}-PDF.pdf`,
                "returnPdfInResponse": true,
                "adjustHeightToFit": false,
                "chromiumPdfOptions": {
                    "format": "A4",
                    "printBackground": true
                }
            }
            
          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
        window.location.href="/home2"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
             {home2 && (<Navigate to={'/home2'} replace={true} />)} 

            <main className=" h-screen flex self-center place-content-center place-items-center">
                <div className=" text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Contact & Insurance Information</h3>
                        </div>

                    </div>
                    <form
                        onSubmit={saveAnswer}
                        className="space-y-4"
                    >
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Last Name

                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={lastname} onChange={(e) => { setLastName(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            First Name

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={firstName} onChange={(e) => { setFirstName(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Middle Initial
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={middleInitial} onChange={(e) => { setMiddleInitial(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                DOB
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={dateOfBirth} onChange={(e) => { setDateOfBirth(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                            
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Email
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={email} onChange={(e) => { setEmail(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Gender
                            </label>
                            
                            <select
                            onChange={(e) => setGender(e.target.value)}
                            defaultValue={gender}
                        >
                            {genders.map((painVal, idx) => (
                            <option key={idx}>{painVal}</option>
                            ))}
                        </select>
                        </div>
                        {/* <div>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                            Street Address
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={street} onChange={(e) => { setStreet(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                City
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={city} onChange={(e) => { setCity(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                            State
                            </label>
                            
                            <select
                            onChange={(e) => setLivingState(e.target.value)}
                            defaultValue={livingState}
                        >
                            {states.map((painVal, idx) => (
                            <option key={idx}>{painVal}</option>
                            ))}
                        </select>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Zip
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={zipCode} onChange={(e) => { setZipCode(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div> */}
                        <div>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                            Phone - Cell
                            </label>
                            
                            <input
                                type="text"
                               
                                
                                value={phnNbr} onChange={(e) => { setPhnNbr(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Phone - Home
                            </label>
                            
                            <input
                                type="text"
                               
                                
                                value={homePhnNbr} onChange={(e) => { setHomePhnNbr(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                            Primary Insurance Co
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={primaryInsurance} onChange={(e) => { setPrimaryInsurance(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Member ID
                            </label>
                            
                            <input
                                type="text"
                               
                                required
                                value={primaryInsuranceNumber} onChange={(e) => { setPrimaryInsuranceNumber(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                            Supplemental Insurance Co
                            </label>
                            
                            <input
                                type="text"
                               
                                
                                value={supplementalInsurance} onChange={(e) => { setSupplementalInsurance(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm text-gray-600 mr-2 font-bold">
                                Member ID
                            </label>
                            
                            <input
                                type="text"
                               
                                
                                value={supplementalInsuranceNumber} onChange={(e) => { setSupplementalInsuranceNumber(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        
                        
                        <button
                        type="submit"                        
                        className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        
                        
                        >Next</button>

                        {/* <Button
                            type="submit"
                            component={Link} 
                            to="/home2"
                            
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next
                            
                        </Button> */}
                        
                    </form>
                </div>
            </main>
        </>
    )
}
export default Survey1;